import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { breakpoints } from 'styles/variables';

import { Row } from 'components/grid/Row';

interface WorkBlockProps {
  children: ReactNode;
}

const WorkBlockWrapper = styled(Row)`
  @media (min-width: ${breakpoints.md}) {
    padding-top: 50px;
  }
`;

export const WorkBlock = ({ children }: WorkBlockProps) => (
  <>
    <WorkBlockWrapper>{children}</WorkBlockWrapper>
  </>
);
