import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Column } from 'components/grid/Column';
import { motion } from 'framer-motion';
import PlayIcon from 'assets/svg/play-small-2.svg';
import LinkIcon from 'assets/svg/external-small-2.svg';

interface WorkBlockImageProps {
  children: ReactNode;
  titleMobile: string;
  external: string;
  md: number;
  lg: number;
}

const WorkTitleMobile = styled.div`
  ${responsiveFont(18, 24)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const ActionIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 10px;
  border-radius: 100px;
  background: #ffffff;
  cursor: pointer;
  pointer-events: none;
  opacity: 0.7;
  svg {
    height: 20px;
    width: 20px;
    margin:8px;
  }
`;

export const WorkBlockImage = ({ children, external, md, lg }: WorkBlockImageProps) => (
  <>
    <Column
      style={{ overflow: 'hidden', position: 'relative' }}
      md={md}
      lg={lg}
      sm={12}
      data-sal="slide-up"
      data-sal-delay="0"
      data-sal-duration="700"
      data-sal-easing="ease"
    >
      {children}
      {external == 'video' && (
        <ActionIcon>
          <PlayIcon />
        </ActionIcon>
      )}
      {external == 'link' && (
        <ActionIcon>
          <LinkIcon />
        </ActionIcon>
      )}
      {/*<WorkTitleMobile>{titleMobile}</WorkTitleMobile>
       */}
    </Column>
  </>
);
