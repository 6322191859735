import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { SimpleFooter } from 'components/footer/SimpleFooter';

import Img from 'gatsby-image';

import { graphql } from 'gatsby';

import Facebook from 'assets/svg/facebook.svg';
import Instagram from 'assets/svg/instagram.svg';
import Linkedin from 'assets/svg/linkedin.svg';

import Back from 'assets/svg/arrow-left.svg';

import Helmet from 'react-helmet';

// tslint:disable no-default-export

const ContactRoot = styled.div`
  background: ${variables.colors.background};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  padding: 0px 0;

  @media (min-width: ${breakpoints.md}) {
    padding: 0px 0;
  }
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

const SubText = styled.p`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 20px;
`;

const Links = styled.div`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 40px;
`;

const LargeInput = styled.input`
  border-bottom: 1px #000000 solid;
  width: 100%;
  ${responsiveFont(24, 38)};
  border-radius: 0px;

  font-family: ${variables.font.familyHeading};
  font-weight: 300;
  outline: none;
  margin-bottom: 20px;
`;

const LargeLabel = styled.label`
  width: 100%;
  ${responsiveFont(22, 28)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
`;

const BackIcon = styled(Back)`
  margin-top: 20px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;

const SubmitButton = styled.div`
  margin-top: 20px;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const ExternalLink = styled.a`
  ${responsiveFont(14, 18)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
  padding-top: 20px;
  color: ${variables.colors.font};
  text-decoration: none;
  transition: 200ms opacity ease-in-out;
  &:hover {
    opacity: 0.5;
  }
`;



export default ({ data }) => {
  return (
    <>
      <Header>
      </Header>
      <Helmet title="Wallpaper" />

      <ContactRoot>
        <Container>
          <Wrapper>
            <Row>
              <Column md={12} lg={12} sm={12}>
                <br />
                <br />
                <br />
                <SectionTitle>Trampolion Wallpaper</SectionTitle>
                <SubText>Select a thumbnail below for the full resolution image.</SubText>
                <br />
                <br />
                <br />
                <WorkBlock>
                    
                    <br/><br/>
                    <WorkBlockImage titleMobile="" lg={2} md={2}>
                      <a href="https://trampolin.studio/LionMobile.jpg">
                        <Img
                          loading="eager"
                          fluid={data.lionImageMobile.childImageSharp.fluid}
                          alt="Lion"
                        />
                      </a>
                    </WorkBlockImage>
                    <WorkBlockImage titleMobile="" lg={10} md={10}>
                      <a href="https://trampolin.studio/Lion.jpg">
                        <Img
                          loading="eager"
                          fluid={data.lionImage.childImageSharp.fluid}
                          alt="Lion"
                        />
                      </a>
                    </WorkBlockImage>
                </WorkBlock>
                <br />
                <br />
                <br />
                <Links>
                  <ExternalLink href="tel:+386 40 564 098">+386 40 564 098</ExternalLink>
                  <br />
                  <ExternalLink href="mailto:hello@trampolin.studio">
                    hello@trampolin.studio
                  </ExternalLink>
                  <SimpleFooter
                    social={[
                      { icon: <Instagram />, to: 'https://www.instagram.com/trampolin.studio' },
                      { icon: <Facebook />, to: 'https://www.facebook.com/Trampolin.studio' },
                      {
                        icon: <Linkedin />,
                        to: 'https://www.linkedin.com/company/trampolin-studio',
                      },
                    ]}
                  ></SimpleFooter>
                  <br />
                  <br />
                  <br />
                </Links>
              </Column>
            </Row>
          </Wrapper>
        </Container>
      </ContactRoot>
    </>
  );
};
export const query = graphql`
  query {
    lionImage: file(relativePath: { eq: "images/Lion.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    lionImageMobile: file(relativePath: { eq: "images/LionMobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
